import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './index.css';
import './i18n';
// import 'dotenv/config'

import App from './App';
import * as serviceWorker from './serviceWorker';
import { setupStore } from './redux/store';
import { persistStore } from 'redux-persist';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/de';
import Loader from '@/Loader';
import { PersistGate } from 'redux-persist/integration/react';

const setLocale = localStorage.getItem('bukazuLanguage');
const locale = setLocale ? setLocale : 'nl';

dayjs.locale(locale);

const store = setupStore({
  user: {},
});
const persistor = persistStore(store);

if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2644c70ddda84090b40797b9126f872d@sentry.io/1496921',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Suspense fallback={<Loader />}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: import.meta.env.VITE_COLOR,
              colorLink: import.meta.env.VITE_COLOR,
              colorLinkActive: '#237804',
              colorLinkHover: '#237804',
            },
          }}
        >
          <App />
        </ConfigProvider>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
