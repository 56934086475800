import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as BukazuLogo } from '../../icons/BukazuLogo.svg';

const SpinLoader = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  @-webkit-keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  /* Standard syntax */
  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  .rotation {
    -webkit-animation: rotation 2s linear infinite; /* Safari */
    animation: rotation 2s linear infinite;
  }

  .txt {
    margin-top: 16px;
  }
`;

function Loader({ title }) {
  return (
    <SpinLoader
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div className="rotation">
        <BukazuLogo style={{ width: '32px' }} />
      </div>
      <div className="txt">{title}...</div>
    </SpinLoader>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
};

Loader.defaultProps = {
  title: 'Building',
};

export default Loader;
