import { ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import './App.css';
import MainRouter from './MainRouter';
import client from './apollo/client';
import { ThemeProvider } from 'styled-components';
import CacheBuster from './cacheBuster';

const Theme = {
  primaryColor: import.meta.env.VITE_COLOR,
};

if (!import.meta.env.PROD) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <ApolloProvider client={client}>
            <ThemeProvider theme={Theme}>
              <div className="App">
                <MainRouter />
              </div>
            </ThemeProvider>
          </ApolloProvider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
