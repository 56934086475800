import { createAction, createReducer } from '@reduxjs/toolkit';

export const setLandlord = createAction('landlord/setLandlord');

const landlordReducer = createReducer(null, builder => {
  builder.addCase(setLandlord, (state, action) => {
    return (state = action.payload);
  });
});

export default landlordReducer;
