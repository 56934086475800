import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import lazyWithRetry from './Routing/LazyWithRetry';

const ConfirmEmail = lazyWithRetry(
  () => import('./components/ConfirmEmail'),
  'ConfirmEmail'
);
const ApplyReview = lazyWithRetry(
  () => import('./components/ApplyReview'),
  'ApplyReview'
);
const Wizard = lazyWithRetry(() => import('./components/Wizard'), 'Wizard');
const LostPassword = lazyWithRetry(
  () => import('./components/LostPassword'),
  'LostPassword'
);
const ResetPassword = lazyWithRetry(
  () => import('./components/LostPassword/ResetPassword'),
  'ResetPassword'
);
const AppRouter = lazyWithRetry(
  () => import('./Routing/AppRouter'),
  'AppRouter'
);
const ContractLess = lazyWithRetry(
  () => import('./components/Contractless'),
  'ContractLess'
);
const AcceptInvitation = lazyWithRetry(
  () => import('./components/AcceptInvitation'),
  'AcceptInvitation'
);

function MainRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/lost_password" component={LostPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="/login" component={Login} />
        <Route path="/sign_up" component={ContractLess} />
        <Route path="/confirm_email/:token" component={ConfirmEmail} />
        <Route path="/accept_invite/:token">
          <AcceptInvitation />
        </Route>
        <Route
          path="/apply_review/:house_id/:locale/:booking_nr"
          component={ApplyReview}
        />
        <Route path="/wizard" component={Wizard} />
        <Route path="/*" exact>
          <AppRouter />
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
