import styled from 'styled-components';

export const FormHolder = styled.section`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100vh;
  position: relative;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  h2,
  form {
    min-width: 400px;
    @media screen and (max-width: 1200px) {
      min-width: 90%;
    }
  }
  .login {
    padding: 16px;
    display: flex;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    &-form {
      flex: 3;
    }
  }
`;

export const OwnerSection = styled.div`
  border: 1px solid #e8e8e8;
  background: #e8e8e8;
  padding: 16px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  div.owner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 900px) {
    margin: 16px 0;
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 900px) {
      padding: 16px 0;
    }
  }
`;

export const Container = styled.section`
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .login-img {
    width: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 1200px) {
      display: none;
    }
    &-text {
      padding: 16px;
      svg {
        width: 50%;
      }
      h1 {
        color: ${props => props.theme.primaryColor};
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }
`;
