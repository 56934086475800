// import { Redirect } from "react-router-dom";
import gql from 'graphql-tag';

import client from '../../apollo/client';
import { loginUser } from '../../redux/actions/userActions';
import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ErrorGraphQL } from '@/Error';

function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Form
      layout="vertical"
      onFinish={values => {
        const { email, password } = values;
        client
          .mutate({
            mutation: LOGIN,
            variables: {
              email,
              password,
            },
          })
          .then(resp => {
            const { data } = resp;
            localStorage.setItem(
              'owner',
              data.loginUser.defaultLandlord.originalOwnerId
            );
            dispatch(loginUser(data.loginUser));
            dispatch(setLandlord(data.loginUser.defaultLandlord));

            window.location.reload();
          })
          .catch(ErrorGraphQL);
      }}
    >
      <Form.Item label={t('app.email')} name="email">
        <Input
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="email"
          name="email"
          autoComplete="email"
          placeholder="Email"
          required={true}
        />
      </Form.Item>
      <Form.Item label={t('app.password')} name="password">
        <Input
          prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          autoComplete="password"
          placeholder={t('app.password')}
          required={true}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          id="app_login"
          htmlType="submit"
          style={{ width: '100%' }}
        >
          {t('app.login')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    loginUser(password: $password, email: $email) {
      id
      email
      first_name
      last_name
      is_super_admin
      userType
      defaultLandlord {
        id
        identifier
        locales
        emailProvider
        originalOwnerId
        bookingFields {
          id
          field_type
          label
          options
        }
        mainContract {
          id
          ends_at
        }
      }
    }
  }
`;
