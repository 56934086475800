import { Redirect, Link } from 'react-router-dom';
import Form from './Form';
import { FormHolder, Container, OwnerSection } from './Login.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as BukazuLogo } from '../../icons/new_logo.svg';

import image from './image.jpg';
import { Button } from 'antd';

function Login({}) {
  const { user } = useSelector(state => state.user);
  const { t } = useTranslation();
  if (user && user.email) {
    const last_path = sessionStorage.getItem('last_path');
    if (last_path) {
      sessionStorage.removeItem('last_path');
      return <Redirect to={last_path} noThrow />;
    } else {
      return <Redirect to="/" noThrow />;
    }
  } else {
    return (
      <Container>
        <div className="login-img">
          <img src={image} alt="Logo" />
          <div className="login-img-text">
            <BukazuLogo />
            <h1>{t('app.welcome.title')}</h1>
          </div>
        </div>
        <FormHolder>
          <div className="login">
            <div className="login-form">
              <h2>{t('app.login_for_landlord_and_owners')}</h2>
              <Form />
              <Link to="/lost_password" id="lost_password_link">
                {t('app.forgot_your_password?')}
              </Link>
            </div>
            <OwnerSection>
              <div className="guest">
                <h3>{t('app.login.login_as_guest')}</h3>
                <a href="https://bukazu.eu/mybooking">
                  <Button type="primary" size="small">
                    {t('app.login.go_to_guest_enviroment')}
                  </Button>
                </a>
              </div>
            </OwnerSection>
          </div>
        </FormHolder>
      </Container>
    );
  }
}

export default Login;
