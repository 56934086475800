import { Button, Result, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Container } from './Error.css';
import * as Sentry from '@sentry/react';

export function ErrorGraphQL(error) {
  Sentry.setContext('GraphQL', error);

  if (error && error.graphQLErrors) {
    error.graphQLErrors.forEach(err => {
      message.error(err.message);
    });
  }
}

export function ErrorQuery(errors) {
  const { t } = useTranslation();
  return (
    <Container>
      <h1>{t('app.something_went_wrong')}</h1>
      <ul>
        {errors.errors.graphQLErrors.map(err => {
          return <li key={err.message}>{err.message}</li>;
        })}
      </ul>
    </Container>
  );
}

export function ErrorBoundary({ children }) {
  const { t } = useTranslation();

  return (
    <Sentry.ErrorBoundary
      fallback={
        <Result
          status="500"
          title={t('app.something_went_wrong')}
          subTitle={t('app.error.explain')}
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              {t('app.actions.reload')}
            </Button>
          }
        />
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
