import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import helpReducer from './helpReducer';
import landlordReducer from './landlordReducer';

const appReducer = combineReducers({
  user: userReducer,
  helpPage: helpReducer,
  landlord: landlordReducer,
});

export default function(state, action) {
  if (action.type === 'SIGNOUT_USER') {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return appReducer(state, action);
}
