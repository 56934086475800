import { SET_HELP_PAGE } from '../actions/types';

const initialState = {
  loading: false,
  helpPage: 'general',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HELP_PAGE:
      return {
        ...state,
        helpPage: action.payload,
      };
    default:
      return state;
  }
}
