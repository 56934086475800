import { loginUser } from '../../redux/actions/userActions';
import { useQuery } from '@apollo/client';
import Loader from '../Loader';
import { useDispatch } from 'react-redux';
import { TOKEN_LOGIN } from './Queryies';
import { setLandlord } from '../../redux/reducers/landlordReducer';

function Wrapper({ children }) {
  const { data, loading, error } = useQuery(TOKEN_LOGIN, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const dispatch = useDispatch();

  if (loading) return <Loader />;

  if (data) {
    localStorage.setItem(
      'owner',
      data.CurrentUser.defaultLandlord.originalOwnerId
    );
    localStorage.setItem('landlord', data.CurrentUser.defaultLandlord.id);
    dispatch(loginUser(data.CurrentUser));
    dispatch(setLandlord(data.CurrentUser.defaultLandlord));
  }

  if (error) {
    localStorage.removeItem('owner');
    localStorage.removeItem('landlord');
    dispatch(loginUser({}));
    dispatch(setLandlord({}));
  }
  return <div>{children}</div>;
}

export default Wrapper;
