import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them)
const url = import.meta.env.VITE_TRANSLATIONS_URL;

const setLocale = localStorage.getItem('bukazuLanguage');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${url}/translations/{{ns}}/{{lng}}.json`,
      addPath: `${url}/translations/add/{{ns}}/{{lng}}`,
      customHeaders: {
        ApiAuthToken: import.meta.env.VITE_TRANSLATIONS_TOKEN,
      },
    },
    // resources,
    lng: setLocale ? setLocale : 'nl',
    whitelist: ['nl', 'de', 'en', 'fr', 'es', 'it'],
    fallbackLng: false,
    ns: 'app',
    defaultNS: 'app',
    load: 'currentOnly',

    // Save missing key
    saveMissing: true,
    saveMissingTo: 'current',

    crossDomain: true,
    withCredentials: true,

    customHeaders: () => ({
      ApiAuthToken: import.meta.env.VITE_TRANSLATIONS_TOKEN,
    }),

    // debug: true,

    keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, //  already safes from xss
    },
  });

export default i18n;
